
  import {Component, Ref, Vue} from "vue-property-decorator";
  import api from "@/api";
  import Tinymce from "@/components/Tinymce/index.vue";
  import {AttachmentHostType, CmsCategoryCreateOrUpdateDto, CmsCategoryType} from "@/api/appService";
  import {ElForm} from "element-ui/types/form";
  import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
  import Ueditor from "@/components/Ueditor/index.vue";

  interface ICategoryTypeSelectItem{
    type:CmsCategoryType,
    label:string
  }

  @Component({
    components: { SimpleUploadImage,Tinymce,Ueditor }
  })
  export default class CreateCmsCategory extends Vue {
    @Ref() readonly dataForm!: ElForm;
    form: CmsCategoryCreateOrUpdateDto = {
      displayName: undefined,
      categoryCode: undefined,
      description: undefined,
      id: 0,
      parentId:undefined,
      redirectUrl: undefined,
      titleImagePath: undefined,
      redirectUrlForWeixinMiniProgram: undefined,
      type: CmsCategoryType.Article,
      displayOrder:undefined
    }

    cmsCategoryType = CmsCategoryType.Content;

    categoryTypeSelectItems:ICategoryTypeSelectItem[] = [
      {type:CmsCategoryType.Article,label:'文章'},
      {type:CmsCategoryType.Content,label:'内容'},
    ];

    get hostType(){
      return AttachmentHostType.CmsCategory;
    }

    created(){
      if(this.$route.params.parentId){
        this.form.parentId = Number(this.$route.params.parentId);
      }
      if(this.$route.params.id){
        api.cmsCategory.get({id:Number(this.$route.params.id)}).then(res=>{
          this.form = {...res};
          console.log(this.form,'description')
        })
      }else{
        if(this.form.parentId){
          api.cmsCategory.getMaxDisplayOrder({parentId:this.form.parentId}).then(res=>{
            this.form.displayOrder = res
          })
        }

      }

    }



    async save() {
      (this.$refs.dataForm as any).validate(async (valid: boolean) => {
        if (valid) {
          if (this.form!.id) {
            await api.cmsCategory.update({
              body: this.form
            });
          } else {
            if(this.form.parentId === 0){
              this.form.parentId = undefined;
            }
            await api.cmsCategory.create({
              body: this.form
            });
          }
          this.$router.back();
          this.$message.success("更新成功");

        }
      });
    }

    cancel() {
      this.$router.back();
      // (this.$refs.dataForm as any).resetFields();
      // this.$emit("input", false);
    }

    roleRule = {
      displayName: [
        {
          required: true,
          message: "分类名称 必填",
          trigger: "blur"
        }
      ],
      categoryCode: [
        {
          required: true,
          message: "栏目编码是必须的",
          trigger: "blur"
        }
      ],
      // description: [
      //   {
      //     required: true,
      //     message: "必填",
      //     trigger: "blur"
      //   }
      // ]
    };
  }
