
  import api from "@/api/index"; //ABP API接口
  import { Vue, Component, Ref } from "vue-property-decorator";

  import { MessageBox } from "element-ui";
  import enumFilter from "@/mixins/filters/enums";

  import {CmsCategoryDto, DepartmentDto} from "@/api/appService";
  import EditDepartment from "./components/edit-department.vue";
  import { createTableTree } from "@/utils/tree";

  @Component({
    name: "CmsCategoryList"
  })
  export default class CmsCategoryList extends Vue {
    @Ref() editForm!: EditDepartment;

    tableItems: CmsCategoryDto[] = [];

    table = {
      listLoading:true
    } as any;

    parentId:number = 0;

    async created() {
      await this.fetchData();
    }

    // 获取表数据
    async fetchData() {
      this.table.listLoading = true;
      await api.cmsCategory.getAll({maxResultCount:1000}).then(res => {
        const tree = createTableTree(
          res.items!,
          "parentId",
          "id",
          null,
          "children"
        );
        console.log(tree);

        this.tableItems = tree;
        this.table.listLoading = false;

        console.log(this.tableItems);
      });
    }

    // 新建
    handleCreate() {
      this.$router.push({name:'category-create',params:{'parentId':'0'}})
    }

    handleCreateChild(row:CmsCategoryDto){
      this.$router.push({name:'category-create',params:{'parentId':row.id!.toString()}})
    }

    // 编辑
    handleEdit(index: number, row: CmsCategoryDto) {
      this.$router.push({name:'category-edit',params:{'id':row.id!.toString()}})
    }

    // 删除
    async handleDelete(index: number, row: DepartmentDto) {
      this.$confirm("你确定删除吗?", "提示").then(async () => {
        await api.cmsCategory.delete({ id: row.id }).then(res => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          this.fetchData();
        });
      });
    }

    async handelOnSaved() {
      await this.fetchData();
    }
  }
