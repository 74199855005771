
import api from "@/api";
import {Component, Vue} from "vue-property-decorator";
import {CmsCategoryDto, CmsContentDto, CmsContentStatus} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import moment from "moment";
import {createNgTree, INgNode} from "@/utils/tree";

@Component({
  name: "CmsContentList",
  components: {PagedTableView}
})
export default class CmsContentList extends Vue {

  queryForm = {
    title: '',
    categoryId: undefined,
    status: undefined,
    enableDataPermissionLimit: false
  }
  categories: CmsCategoryDto[] = [];
  categoriesTree: INgNode<CmsCategoryDto>[] = [];
  selectionList = [];
  isShow = false;
  newCategoryId: number = 0;

  created() {
    this.fetchCategories()
  }

  async fetchCategories() {
    await api.cmsCategory.getAll({maxResultCount: 1000, needShowContentCategory: false}).then(res => {
      this.categories = res.items!;
      this.categoriesTree = createNgTree(res.items!, 'parentId', 'id', null, 'children', null, false, 'id');
    })
  }

  // 获取已选择数据
  getSelectionList(rows: any) {
    this.selectionList = rows
  }

  fetchData(params: any) {
    params.enableDataPermissionLimit = false;
    return api.cmsContent.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({name: 'content-create'})
  }

  // 状态
  formatStatus(value: any, column: any, cellValue: any) {
    switch (cellValue) {
      case CmsContentStatus.Draft:
        return "草稿"
      case CmsContentStatus.Published:
        return "已发布"
    }
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case 'detail':
        this.handleDetail($row.item)
        break
      case 'edit':
        this.handleEdit($row.index, $row.item)
        break

      case 'publish':
        this.handlePublish($row.item)
        break

      case 'cancelPublish':
        this.handleCancelPublish($row.item)
        break

      case 'moveToTrash':
        this.handleMoveToTrash($row.item)
        break

      case 'stickyPost':
        this.handleStickyPost($row.item);
        break

      case 'cancelStickyPost':
        this.handleCancelStickyPost($row.item);
        break
    }
  }

  // 跳转详情页
  handleDetail(item: CmsContentDto) {
    this.$router.push({name: 'content-detail', params: {'id': item.id!.toString()}})
  }

  // 跳转编辑页
  handleEdit(index: number, item: CmsContentDto) {
    this.$router.push({name: 'content-edit', params: {'id': item.id!.toString()}})
  }

  // 发布

  handlePublish(item: CmsContentDto) {
    this.$confirm('确定发布吗?', '提示').then(() => {
      api.cmsContent.publish({body: {id: item.id}}).then(() => {
        this.$message.success('发布成功');
      });
    })
  }

  // 取消发布
  handleCancelPublish(item: CmsContentDto) {
    this.$confirm('确定取消发布吗?', '提示').then(() => {
      api.cmsContent.cancelPublish({body: {id: item.id}}).then(() => {
        this.$message.success('取消成功');
      });
    })
  }

  // 删除
  handleMoveToTrash(item: CmsContentDto) {
    this.$confirm('确定删除吗?', '提示').then(() => {
      api.cmsContent.moveToTrash({body: {id: item.id}}).then(() => {
        this.$message.success('删除成功');
      });
    })
  }

  handleCategoriesChange(value: any) {
    this.queryForm!.categoryId = value![value.length - 1]
  }

  // 置顶
  handleStickyPost(item: CmsContentDto) {
    this.$confirm('确定置顶吗?', '提示').then(() => {
      api.cmsContent.stickyPost({body: {id: item.id}}).then(() => {
        this.$message.success('发布成功');
      });
    })
  }

  // 取消置顶
  handleCancelStickyPost(item: CmsContentDto) {
    this.$confirm('确定置顶发布吗?', '提示').then(() => {
      api.cmsContent.cancelStickyPost({body: {id: item.id}}).then(() => {
        this.$message.success('取消成功');
      });
    })
  }


  handleSetCategory(value: any) {
    this.newCategoryId = value![value.length - 1]
  }

  showSetCategory() {
    this.isShow = true
    this.newCategoryId = 0
  }

  // 设置文章栏目
  setCategory() {
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message({
        type: "error",
        message: "请先选择文章!"
      });
      return
    }
    if (this.newCategoryId <= 0) {
      this.$message({
        type: "error",
        message: "请选择栏目!"
      });
      return
    }
    this.$confirm("确认设置栏目吗?", "提示").then(async () => {
      this.isShow = false
      let ids: any = []
      this.selectionList.forEach((item: any) => {
        ids.push(item.id)
      })
      await api.cmsContent.batchChangeCategory({
        contentIds: ids.join(','),
        categoryId: this.newCategoryId
      }).then(() => {
        this.$message({
          type: "success",
          message: "确认成功!"
        });
      });
    });
  }

  handleSyncWechatNews() {
    this.$confirm('确定开始同步微信公众号文章吗?', '提示').then(() => {
      api.cmsContent.syncWechatNews().then(() => {
        this.$message.success('同步任务已创建，请耐心等待');
      });
    })
  }

  selectable(row: any, index: any) {
    return row.status !== 'Published'
  }

}
