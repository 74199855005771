
import api from "@/api";
import {Component, Vue} from "vue-property-decorator";
import {CmsCategoryDto, CmsContentDto} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import moment from "moment";
import {createNgTree, INgNode} from "@/utils/tree";

@Component({
  name: "CmsContentTrashList",
  components: {PagedTableView}
})
export default class CmsContentTrashList extends Vue {

  queryForm = {
    title: '',
    categoryId: undefined,
    status: 'Deleted',
    enableDataPermissionLimit: false
  }
  categories: CmsCategoryDto[] = [];
  categoriesTree: INgNode<CmsCategoryDto>[] = [];

  created() {
    this.fetchCategories()
  }

  async fetchCategories() {
    await api.cmsCategory.getAll({maxResultCount: 1000}).then(res => {
      this.categories = res.items!;
      this.categoriesTree = createNgTree(res.items!, 'parentId', 'id', null, 'children', null, false, 'id');
    })
  }

  fetchData(params: any) {
    params.status = 'Deleted';
    params.enableDataPermissionLimit = false;
    return api.cmsContent.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({name: 'content-create'})
  }

  // 时间
  formatCreationTime(value: any, column: any, cellValue: any) {
    return moment(cellValue).format('YYYY-MM-DD');
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case 'detail':
        this.handleDetail($row.item)
        break

      case 'restore':
        this.handleRestore($row.item)
        break

      case 'deleted':
        this.handleDelete($row.item)
        break
    }
  }

  // 跳转编辑页
  handleEdit(index: number, item: any) {
    this.$router.push({name: 'content-edit', params: {'id': item.id!.toString()}})
  }

  handleRestore(item: CmsContentDto) {
    this.$confirm('确定恢复吗?', '提示').then(() => {
      api.cmsContent.restoreFromTrash({body: {id: item.id}}).then(() => {
        this.$message.success('恢复成功');
      });
    })
  }

  handleDetail(item: CmsContentDto) {
    this.$router.push({name: 'content-detail', params: {'id': item.id!.toString()}})
  }

  handleDelete(item: CmsContentDto) {
    this.$confirm('确定删除吗?', '提示').then(() => {
      api.cmsContent.delete({id: item.id}).then(() => {
        this.$message.success('删除成功');
      });
    })
  }

  handleCategoriesChange(value: any) {
    this.queryForm!.categoryId = value![value.length - 1]
  }

}
