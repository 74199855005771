
  import {Component, Ref, Vue} from "vue-property-decorator";
  import Tinymce from "@/components/Tinymce/index.vue";
  import {ElForm} from "element-ui/types/form";
  import {AttachmentDto, AttachmentHostType, CmsCategoryDto, CmsContentCreateOrUpdateDto} from "@/api/appService";
  import api from "@/api";
  import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
  import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
  import Ueditor from "@/components/Ueditor/index.vue";
  import {createNgTree, getCategoryIdList, INgNode} from "@/utils/tree";

  @Component({
    components: {SimpleUploadImage, Tinymce, MultipleUploadFile,Ueditor }
  })

  export default class EditCmsContent extends Vue {
    @Ref() dataForm!: ElForm;
    categories:CmsCategoryDto[] = [];
    categoriesTree:INgNode<CmsCategoryDto>[] = [];
    cmsContentId?:number = 0;
    selectedCategory?:number[] = [];
    attachmentList?:AttachmentDto[] = [];
    submitting:boolean = false;
    form:CmsContentCreateOrUpdateDto | undefined = {
      id: 0,
      title:'',
      titleImageUrl: '',
      cmsCategoryId: 0,
      summary:'',
      author:'',
      source:'',
      publishTime: undefined,
      isPublish: false,
      description: '',
      savedAttachments: []
    };
    activeNames:any[] = ['1','2','3']

    async created() {
      if(this.$route.params.id){
        this.cmsContentId = Number(this.$route.params.id);
        await this.fetchDetail();
      }

      await this.fetchCategories();
    }

    get hostType(){
      return AttachmentHostType.CmsContentTitleImage;
    }

    get hostTypeFile() {
      return AttachmentHostType.CmsContentAttachment;
    }
    // 获取详情
    async fetchDetail() {
      await api.cmsContent.getDetail({id:this.cmsContentId}).then(res=>{
        this.form = {...res};
        this.form!.savedAttachments =this.form!.savedAttachments??[];
        if(res.attachments) {
            this.attachmentList = res.attachments??[];
        }
      })
    }
    //选择栏目
    handleCategoriesChange(value:any) {
      this.form!.cmsCategoryId = value![value.length-1]
    }
    // 折叠面板Collapse 事件
    handleCollapseChange(val:any) {
      console.log(val,'Collapse')
    }
    //获取栏目
   async fetchCategories() {
     await api.cmsCategory.getAll({maxResultCount:1000, needShowContentCategory: false}).then(res=>{
        this.categories = res.items!;
        this.categoriesTree = createNgTree(res.items!,'parentId','id',null,'children',null,false, 'id');
        if(this.cmsContentId) {
          this.selectedCategory = getCategoryIdList(this.categories, Number(this.form!.cmsCategoryId))
        }
        console.log(this.categoriesTree,'this.categoriesTree')
     })
    }

    // 返回
    private cancel() {
      this.$router.back();
    }
    // 保存为草稿
    private async saveAsDraft() {
      this.save(false);
    }
    // 发布
    private async saveAndPublish() {
      this.save(true);
    }

    private async save(CmsContentStatus:boolean) {
      console.log(this.form);
      (this.$refs.dataForm as any).validate(async (valid: boolean) => {
        if (valid) {
          this.form!.isPublish = CmsContentStatus;
          this.submitting = true
          let fn = undefined
          if (this.form!.id) {
            fn = api.cmsContent.update

          } else {
            fn = api.cmsContent.create
          }
          await fn({ body: this.form }).then(res=>{
            this.$message({
              type: "success",
              message: "成功"
            });
            setTimeout(() => {
              this.$router.push({ name: "content-list" });
            }, 200);
          }).catch(()=>{
            this.submitting = false
          });
        } else {
          this.$message({
            type: 'error',
            message: '请检查表单'
          })
        }
      });
    }

    roleRule = {
      title: [{
        required: true,
        message: '请填写标题',
        trigger: 'blur'
      }],
      cmsCategoryId: [
        {
          required: true,
          message: "请选择所属栏目",
          trigger: "blur"
        }
      ],
      publishTime: [{
        required: true,
        message: '请选择时间',
        trigger: 'blur'
      }],
      description: [
        {
          required: true,
          message: "请填写内容",
          trigger: "blur"
        }
      ]
    };
  }
