
import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref
} from "vue-property-decorator";

import api from "@/api";
import {
  CmsFriendLinkDto,
  CmsFriendLinkCreateOrUpdateDto, AttachmentHostType
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";

@Component({
  name: "EditFriendLink",
  components: { SimpleUploadImage }
})
export default class EditFriendLink extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  defaultData: CmsFriendLinkCreateOrUpdateDto = {
    name: undefined,
    link: undefined,
    logo:undefined,
    displayOrder: 0,
    id: 0
  };

  show = false;
  form: CmsFriendLinkCreateOrUpdateDto = { ...this.defaultData };

  get hostType() {
    return AttachmentHostType.FriendlyLinkLogo;
  }

  get title() {
    if (this.dataId) {
      return `编辑 ${this.form.name}`;
    } else {
      return "新建";
    }
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog"
      });

      if (this.dataId) {
        await api.cmsFriendLink
          .get({ id: this.dataId })
          .then((res: CmsFriendLinkDto) => {
            this.form = res!;
          })
          .finally(() => {
            setTimeout(() => {
              loading.close();
            }, 200);
          });
      } else {
        this.form = { ...this.defaultData };
        //
        setTimeout(() => {
          loading.close();
        }, 200);
      }
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {
    console.log(this.form);

    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.cmsFriendLink.update({
            body: { ...this.form }
          });
        } else {
          await api.cmsFriendLink.create({
            body: { ...this.form }
          });
        }
        this.show = false;
        this.$message.success("更新成功");
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }

  linkRule = {
    name: [
      {
        required: true,
        message: "请输入合作机构",
        trigger: "blur"
      }
    ],
    // link: [
    //   {
    //     required: true,
    //     message: "请输入跳转链接",
    //     trigger: "blur"
    //   }
    // ],
    displayOrder: [
      {
        required: true,
        message: "请输入显示顺序",
        trigger: "change"
      },
      {
        type: "number",
        message: "显示顺序必须为数字",
        trigger: "change"
      }
    ]
  };
}
